@import 'scss/common/vars';
@import 'scss/common/typography';

.sms-form {
  .ant-row {
    margin: 0;
  }

  .sms-form__send-new {
    display: block;
    margin: 20px auto 0;
    padding: 0;
    border: none;
    background: transparent;
    color: $text-dark;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }

  .sms-form__send {
    text-align: center;
  }

  &__code-info {
    display: flex;
    align-items: center;
    margin: 32px 0;
    color: $grey2;

    @include typo-14-normal();

    .anticon {
      margin-right: 8px;
      color: $grey2;

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}
