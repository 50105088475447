@import 'scss/common/vars';

.raport-assesstment {
  padding: 2.4rem 1.6rem;

  .ant-col-md-8 {
    @media only screen and (min-width: $tabletD) {
      margin-bottom: 16px;
    }
  }

  @media only screen and (min-width: $tabletD) {
    padding: 1.6rem 0;
  }
}
