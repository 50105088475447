@import 'scss/common/vars';

.collapse {
  margin-bottom: 20px;
  overflow: hidden;

  &.collapse--active {
    .collapse__content {
      display: block;
      height: auto;
    }

    .collapse__btn-more {
      color: $purple;
    }
  }

  .collapse__header {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
  }

  .collapse__btn-more {
    display: flex;
    padding: 0;
    transition: transform 0.2s ease;
    border: none;
    background: transparent;
    color: $grey2;
    font-size: 14px;
    cursor: pointer;
    will-change: transform;
  }

  .collapse__content {
    display: none;
    max-width: 511px;
    height: 0;
    padding: 1rem 0;
    overflow: hidden;
    opacity: 0.6;
    color: $text-dark;
    font-size: 1.4rem;
    line-height: 19.6px;
    word-break: break-word;

    p {
      margin: 0;
      color: $text-dark;
      font-size: 1.4rem;
      line-height: 19.6px;
    }
  }
}
