@import 'scss/common/vars';
@import 'scss/common/typography';

.box {
  width: 100%;
  margin: 0 auto 0;
  padding: 0 2.4rem;
  border-radius: 8px;
  background: $white;
  box-shadow: $light-shadow;

  &--heading {
    margin-bottom: 16px;
  }

  &__inner {
    margin: 0 auto;

    &--heading {
      position: relative;
      max-width: 656px;
      padding: 48px 0 16px 0;

      @media only screen and (min-width: $tabletD) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 34px 0;
      }

      img {
        position: absolute;
        top: -31px;
        right: 0;
        bottom: auto;
        left: 0;
        width: 62px;
        height: 62px;
        margin: auto;

        @media only screen and (min-width: $tabletD) {
          position: relative;
          top: 0;
          width: 80px;
          height: 80px;
          margin: 0;
        }
      }

      section {
        h3 {
          color: $purple;

          @include typo-20-bold();
        }

        p {
          @include typo-16-normal();
          margin-top: 8px;
          margin-bottom: 0;
          color: $text;
        }

        ul {
          @include typo-16-bold();
          padding-left: 20px;

          li {
            color: $text;
          }
        }

        @media only screen and (min-width: $tabletD) {
          padding-left: 24px;

          h3 {
            @include typo-24-bold();
          }

          p {
            margin-top: 12px;
          }
        }
      }
    }

    &--content {
      max-width: 548px;
      padding: 40px 0;
    }
  }

  &.wider {
    .box__inner--content {
      padding-top: 48px;
      padding-bottom: 50px;
    }

    &--heading {
      padding: 0 120px;

      @media only screen and (max-width: $tabletD) {
        padding: 0 2.4rem;
      }

      .box__inner--content {
        width: 480px;
        max-width: 480px;

        @media only screen and (max-width: $tabletD) {
          width: 100%;
        }
      }
    }

    &--content {
      padding: 0 48px;

      @media only screen and (max-width: $tabletD) {
        padding: 0 2.4rem;
      }

      .box__inner--content {
        width: 624px;
        max-width: 624px;

        @media only screen and (max-width: $tabletD) {
          width: 100%;
        }
      }
    }
  }
}
