@import 'scss/common/vars';

.smeo-agreement {
  width: 100%;
  max-width: 720px;
  padding: 32px;
  background: $white;

  &__error-title {
    margin-bottom: 24px;
    color: $purple;
    font-size: 20px;
    text-align: center;
  }

  &__error-subtitle {
    color: $text-dark;
    font-size: 16px;
    text-align: center;
  }

  &__pdf-wrapper {
    max-width: 645px;
    height: 330px;
    margin: 0 auto;

    iframe {
      border: 0;
    }
  }

  &__attachment-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 36px;
    color: $text-dark;
    font-size: 16px;
    line-height: 19px;
  }

  &__attachment-title {
    margin: 12px 20px 0 0;
    font-weight: normal;
  }

  &__attachment {
    margin: 12px 0 0;
    color: $text-dark;
    font-weight: bold;
    text-decoration-line: underline;
  }

  &__btn-wrapper {
    margin-top: 88px;
  }
}
