@import 'scss/common/vars';

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 40px;

  a.navigation-btn--prev {
    order: 2;

    @media only screen and (min-width: $mobileD) {
      order: 1;
    }
  }

  button,
  .navigation-btn--next {
    order: 1;

    @media only screen and (min-width: $mobileD) {
      order: 2;
    }
  }
}
