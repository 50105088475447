@import 'scss/common/vars';
@import 'scss/common/typography';

.navigation-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 142px;
  min-height: 43px;
  margin: 8px 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  white-space: nowrap;

  @media only screen and (min-width: $mobile) {
    width: auto;
    margin: 8px;
  }

  &.ant-btn-loading {
    display: flex;

    .anticon {
      margin: 0;
    }

    a {
      color: $white;
    }
  }
}

.navigation-btn--prev {
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid $light-purple;

  > a:only-child {
    color: $black;
  }

  &.ant-btn-loading {
    a {
      color: $black;
    }

    .anticon {
      background: transparent;
      color: $black;
    }
  }

  &:hover,
  &:focus {
    background-color: $light-purple;
    color: inherit;
  }
}

.ant-btn.navigation-btn--next {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: $purple;

  > a:only-child {
    color: $white;
  }

  &:hover,
  &:focus {
    background-color: $dark-purple;
    color: $white;
  }
}

.submit-btn {
  width: 100%;

  @media only screen and (min-width: $mobile) {
    width: auto;
  }

  &.small {
    @include typo-14-normal();
    width: auto;
    min-height: 32px;
    margin: 0;
    padding: 0 16px;
  }
}
