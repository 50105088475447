@import 'scss/common/vars';
@import 'scss/common/typography';

.report-info {
  margin-top: 1px;
  padding: 24px 16px 40px 16px;
  background: $white;
  box-shadow: 0 15px 15px 5px rgba(194, 186, 228, 0.05);

  @media only screen and (min-width: $tabletD) {
    margin-top: 0;
    margin-bottom: 62px;
    padding: 0;
    background: transparent;

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 32px;
      padding: 32px;
      border-radius: 8px;
      background: $white;
    }
  }

  p {
    @include typo-14-normal();
    color: $text-dark;
    text-align: center;

    @media only screen and (min-width: $tabletD) {
      @include typo-16-normal();
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0;
    padding: 0;
    list-style: none;

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
      width: calc(100% - 140px);
      margin: 0;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 16px;

      @media only screen and (min-width: $tabletD) {
        padding-right: 20px;
      }

      h5 {
        margin-bottom: 6 px;
        color: $grey2;

        @include typo-14-normal();
      }

      h3 {
        @include typo-18-medium();
        margin-bottom: 0;

        @media only screen and (min-width: $tabletD) {
          @include typo-20-medium();
          max-width: 200px;
        }
      }
    }
  }

  .submit-btn.small {
    width: 100%;

    @media only screen and (min-width: $tabletD) {
      width: auto;
      margin-top: 28px;
      margin-left: 40px;
    }
  }
}
