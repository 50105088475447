@import 'antd/dist/antd.css';

.ant-form-item,
.ant-form-item-row {
  display: flex;
  flex-direction: column;
}

// all labels
.ant-form-item-label {
  text-align: left;

  label {
    height: auto;
    margin: 0 0 8px;
    color: $text-dark;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 22px;
  }
}

// validator Errors
// .ant-form-item-explain.ant-form-item-explain-error {
//   line-height: 16px;
//   margin: 8px 0 24px;
// }

// Input & InputPassword
.ant-input,
.ant-input-password,
.ant-picker {
  padding: 15px 12px;
  border-radius: 2px;
  border-color: transparent;
  background-color: $white2;
  font-size: 1.6rem;
  line-height: 140%;

  &::placeholder {
    color: $grey2;
  }

  &:focus,
  &:hover {
    border-color: $purple;
    box-shadow: none;
  }
}

.ant-picker {
  width: 100%;

  @media only screen and (min-width: $tabletD) {
    width: 200px;
  }
}

.ant-picker-input > input {
  font-size: 1.6rem;

  &::placeholder {
    font-size: 1.6rem;
  }

  &:focus,
  &:hover {
    border-color: $purple;
    box-shadow: none;
  }
}

.ant-picker-suffix {
  color: $light-purple;
}

// InputPassword
.ant-form-item-control-input-content {
  cursor: text;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $purple;
  box-shadow: none;
}

// InputNumber
.ant-input-number {
  width: 100%;
  border-radius: 2px;
  border-color: transparent;
  background-color: $white2;
  font-size: 1.6rem;
  line-height: 140%;

  &-handler:hover .anticon {
    color: $purple;
  }

  &-input {
    padding: 0 12px;

    &::placeholder {
      color: $grey2;
    }
  }

  &-lg input {
    height: 52px;
  }

  &:hover,
  &:focus,
  &-focused {
    border-color: $purple;
    box-shadow: none;
  }
}

// Select
.ant-select-single.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    height: 54px;
    padding: 0 12px;
    border-radius: 2px;
    border-color: transparent;
    background-color: $white2;
    font-size: 1.6rem;

    &:hover {
      border-color: $purple;
    }
  }
}

.ant-select-single.ant-select:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 52px;
}

.ant-select-single.ant-select-open .ant-select-selection-item,
.ant-select-selection-placeholder {
  color: $grey2;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  height: 52px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $purple;
  box-shadow: none;
}

.ant-select-item {
  padding: 10px 12px;
}

// Checkboxes

.ant-checkbox {
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $purple;
  }

  .ant-checkbox-inner {
    border-color: transparent;
    background-color: $white3;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $purple;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    border-color: $purple;
    background-color: $purple;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $purple;
}

// Button

.ant-btn {
  min-width: 142px;
  min-height: 43px;
  margin: 8px 0;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  @media only screen and (min-width: $mobile) {
    margin: 8px;
  }

  &-default {
    border-color: $purple;
    color: $black;

    &:hover,
    &:focus {
      border-color: $purple;
      background-color: $light-purple;
      color: $black;
    }
  }

  &-primary {
    border-color: $purple;
    background-color: $purple;

    &:hover,
    &:focus {
      border-color: $purple;
      background-color: $dark-purple;
    }
  }
}

.ant-result-info .ant-result-icon > .anticon {
  color: $yellow;
}

.ant-result-success .ant-result-icon > .anticon {
  color: $green;
}

.ant-result-error .ant-result-icon > .anticon {
  color: $red;
}

// Modal

.ant-modal-root {
  .ant-modal-mask {
    @media only screen and (max-width: $tabletD) {
      background: $white;
    }
  }

  .ant-modal-header {
    @media only screen and (max-width: $tabletD) {
      padding: 0;
    }
  }

  .ant-modal-content {
    @media only screen and (max-width: $tabletD) {
      box-shadow: none;
    }

    .ant-modal-close-x {
      @media only screen and (max-width: $tabletD) {
        display: none;
      }
    }
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-modal-title {
    @media only screen and (min-width: $tabletD) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

// .ant-spin-spinning {
//   display: flex;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   align-items: center;
//   justify-content: center;
//   width: 40px;
//   height: 40px;
//   margin: auto;

//   .ant-spin-dot,
//   svg {
//     width: 100%;
//     height: 100%;
//     color: $purple;
//   }
// }

// .ant-picker-input
