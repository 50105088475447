@import 'scss/common/vars';
@import 'scss/common/typography';

.raport {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.thank-page {
  .ant-result {
    padding: 0;
  }

  .ant-result-icon {
    margin-bottom: 50px;
  }

  .ant-result-title {
    margin-bottom: 30px;
    color: $purple;
    font-weight: 500;
    line-height: 28px;
  }

  .ant-result-subtitle {
    color: black;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
  }

  .btn-wrapper {
    margin-top: 50px;
  }

  &__btn.ant-btn {
    height: auto;
    padding: 16px 50px;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }
}
