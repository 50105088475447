@import 'scss/common/vars';

.kontomatik-wrapper {
  .kontomatik-wrapper__btn-wrapper {
    margin: 0;
  }

  .ant-result-error {
    padding: 0;
  }
}
