@import 'scss/common/vars';
@import 'scss/common/typography';

//antd wrapper outside app root
.register-form-popover {
  max-width: 415px;

  .ant-popover-arrow {
    display: none;
  }

  &__wrapper {
    display: flex;
    padding: 4px 8px;
    color: $purple;
  }

  &__icon {
    margin-right: 1.6rem;
    font-size: 2.4rem;
  }

  &__text {
    color: $black;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.register-form {
  &__popover-text {
    margin-bottom: 43px;
    color: $grey2;

    @include typo-14-normal();
    line-height: 17px;
    cursor: pointer;
  }

  &__popover-icon {
    margin-right: 9px;
    font-size: 13px;
  }

  &__consents {
    margin: 0 0 67px;

    a {
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      background: transparent;
      color: inherit;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__login {
    margin-top: 16px;
    text-align: center;

    .register-form__link {
      color: $text-dark;
      font-weight: 600;
    }
  }
}
