@import 'scss/common/vars';
@import 'scss/common/typography';

.ant-modal.rating-modal {
  top: 0;
  min-width: 100%;
  margin: 0;

  @media only screen and (min-width: $tabletD) {
    top: 100px;
    min-width: auto;
    margin: auto;
  }

  .box__inner {
    padding: 24px 0;

    @media only screen and (min-width: $tabletD) {
      max-width: 100%;
      padding: 0;
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.rating-modal {
  padding: 0;
  box-shadow: none;

  @media only screen and (max-width: $tabletD) {
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin: 0;
  }

  &__header-title {
    @media only screen and (max-width: $tabletD) {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 18px 0;
      border: 0;
      background: $purple;
    }

    h4 {
      @include typo-20-medium();
      margin-bottom: 0;

      @media only screen and (max-width: $tabletD) {
        color: $white;

        @include typo-18-medium();
      }
    }

    button {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      padding: 0 20px;
      border: 0;
      background: transparent;

      @media only screen and (min-width: $tabletD) {
        display: none;
      }

      .anticon {
        color: $white;
      }
    }
  }

  &__header-desc {
    width: 100%;
    margin: 0;
    padding: 16px;
    background: $grey;
    color: $text-dark;
    text-align: center;

    @include typo-14-normal();

    > span {
      display: none;
      margin-right: 5px;
    }

    @media only screen and (min-width: $tabletD) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      margin-left: 16px;
      padding: 0;
      padding-right: 16px;
      background: transparent;
      text-align: left;

      @include typo-16-medium();

      > span {
        display: inline;
      }
    }
  }

  h4 {
    @include typo-16-medium();

    @media only screen and (min-width: $tabletD) {
      @include typo-20-medium();
    }
  }

  &__stripe {
    @media only screen and (min-width: $tabletD) {
      content: '';
      display: block;
      width: 100%;
      height: 9px;
      margin-bottom: 16px;
      border-radius: 8px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;

    @media only screen and (min-width: $tabletD) {
      justify-content: flex-start;
    }

    > div {
      width: calc(50% - 10px);

      @media only screen and (min-width: $tabletD) {
        width: calc(100% / 3);
      }
    }

    .result {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__description {
        margin: 0;
        color: $black;
        text-align: left;

        p {
          display: none;
          margin-bottom: 0;
          color: $text-dark;

          @media only screen and (min-width: $tabletD) {
            display: block;
          }
        }

        @include typo-14-medium();

        span {
          width: 100%;
          color: $yellow;
        }
      }

      .ant-result {
        padding: 0;
        padding-right: 12px;

        .ant-result-icon {
          margin-bottom: 0;
        }

        .anticon,
        svg {
          width: 36px;
          height: 36px;
        }
      }

      .ant-result .ant-result-warning {
        border-radius: 50%;
        color: $yellow;
      }
    }

    .mark {
      display: flex;
      align-items: center;
      justify-content: center;

      &__description {
        margin-bottom: 0;
        color: $text-dark;
        text-align: left;

        @include typo-14-medium();
      }

      &__number {
        padding-right: 12px;
        color: $text-dark;
        font-size: 3.2rem;
      }
    }

    .additional-information {
      display: none;

      small {
        color: $text-dark;

        @include typo-14-normal();
      }

      @media only screen and (min-width: $tabletD) {
        display: block;
      }
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 40px;
    padding: 16px;
    border-radius: 2px;

    .anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-right: 16px;
      border-radius: 50%;

      svg {
        width: 14px;
        color: $white;
      }
    }

    h5 {
      @include typo-14-medium();
      margin-bottom: 8px;
    }

    p {
      @include typo-14-normal();
      color: $text-dark;
    }
  }

  &__entry {
    padding: 0;
    list-style: none;

    @media only screen and (min-width: $tabletD) {
      display: none;
    }

    li {
      margin-bottom: 16px;

      ul {
        padding: 0;
        border: 1px solid $grey3;
        border-radius: 4px;
        list-style: none;

        .with-dot {
          position: relative;

          span {
            @include typo-14-normal();
          }

          p > span {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            width: 13px;
            height: 13px;
            margin: auto;
            border-radius: 50%;
          }
        }

        &.red {
          border-color: $red;

          .with-dot > p > span {
            background: $red;
          }
        }

        &.green {
          border-color: $green;

          .with-dot > p > span {
            background: $green;
          }
        }

        &.yellow {
          border-color: $yellow;

          .with-dot > p > span {
            background: $yellow;
          }
        }

        li {
          display: flex;
          position: relative;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 0;
          padding: 12px 16px;
          border-bottom: 1px solid $grey;

          p {
            max-width: 50%;
            margin: 0;

            @include typo-14-normal();
          }

          span {
            @include typo-14-medium();
            max-width: 50%;
            text-align: right;
          }
        }
      }
    }
  }

  table {
    display: none;
    margin-top: 16px;

    @media only screen and (min-width: $tabletD) {
      display: table;
      width: 100%;
    }

    thead {
      background: $white3;

      th {
        padding: 16px;
        text-align: left;

        @include typo-12-normal();
      }
    }

    tbody {
      tr {
        .dot {
          display: block;
          width: 12px;
          height: 12px;
          margin: auto;
          border-radius: 50%;

          &--error {
            background: $red;
          }

          &--warning {
            background: $yellow;
          }

          &--success {
            background: $green;
          }
        }
      }

      td {
        padding: 16px;
        border-bottom: 1px solid $white3;

        @include typo-14-normal();
      }
    }
  }

  &__inline-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    margin-bottom: 24px;
    padding: 0;
    background: $white2;
    list-style: none;

    @media only screen and (min-width: $tabletD) {
      flex-direction: row;
    }

    li {
      padding: 12px 0;
      text-align: center;

      @include typo-14-normal();

      b {
        @include typo-16-bold();
      }
    }
  }

  &__delay {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    padding: 0;
    padding-top: 32px;
    list-style: none;
    text-align: left;

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    li {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      padding-left: 25px;

      @media only screen and (min-width: $tabletD) {
        width: auto;
        margin-bottom: 0;
        margin-left: 40px;
      }

      @include typo-14-medium();

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 13px;
        height: 13px;
        margin: auto;
        border-radius: 50%;
      }

      &.good::after {
        background: $green;
      }

      &.medium::after {
        background: $yellow;
      }

      &.bad::after {
        background: $red;
      }
    }
  }

  .anticon-arrow-up,
  .anticon-arrow-down,
  .anticon-arrow-right {
    color: $text-dark;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .anticon-arrow-up {
    transform: rotate(45deg);
    color: $green;
  }

  .anticon-arrow-down {
    transform: rotate(45deg);
    color: $red;
  }

  &__descriptions-mobile {
    display: block;
    margin-bottom: 20px;
    text-align: center;

    @media only screen and (min-width: $tabletD) {
      display: none;
    }
  }

  &--success {
    .rating-modal__stripe {
      @media only screen and (min-width: $tabletD) {
        background: $green;
      }
    }

    .rating-modal__info {
      border: 1px solid $green;
      background: $light-green;

      .anticon {
        background: $green;
      }
    }

    .rating-modal__entry {
      border-color: $green;
    }

    .result__description span {
      color: $green;
    }
  }

  &--warning {
    .rating-modal__stripe {
      @media only screen and (min-width: $tabletD) {
        background: $yellow;
      }
    }

    .rating-modal__info {
      border: 1px solid $yellow;
      background: $light-yellow;

      .anticon {
        background: $yellow;
      }
    }

    .rating-modal__entry {
      border-color: $yellow;
    }

    .result__description span {
      color: $yellow;
    }
  }

  &--error {
    .rating-modal__stripe {
      @media only screen and (min-width: $tabletD) {
        background: $red;
      }
    }

    .rating-modal__info {
      border: 1px solid $red;
      background: $light-red;

      .anticon {
        background: $red;
      }
    }

    .rating-modal__entry {
      border-color: $red;
    }

    .result__description span {
      color: $red;
    }
  }
}
