@import 'scss/common/vars';
@import 'scss/common/typography';

.expired-box {
  margin-bottom: 0.8rem;
  padding: 2.4rem 1.6rem;
  text-align: center;

  @media only screen and (min-width: $tabletD) {
    margin-bottom: 1.6rem;
    padding: 1.6rem 0;
  }

  h4 {
    width: 100%;
    margin-bottom: 24px;
    color: $light-purple;

    @include typo-20-medium();
  }

  p {
    @include typo-16-normal();
    width: 100%;
    margin-bottom: 24px;
    color: $text;
  }

  .box__inner--content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    .icon {
      width: 100%;
    }
  }
}
