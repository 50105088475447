/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $purple;
}
