@import 'scss/common/vars';
@import 'scss/common/typography';

.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  text-align: center;

  &__contact {
    margin-top: 40px;

    p {
      @include typo-16-normal();
      margin-bottom: 0;
      color: $text-dark;
    }

    a {
      @include typo-16-bold();
      color: $text-dark;
    }
  }

  .anticon-redo svg {
    width: 60px;
    height: 60px;
    fill: $purple;
  }

  .ant-progress-status-exception {
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: $red;
    }

    .ant-progress-inner {
      background: $red;
    }

    .ant-progress-circle-trail {
      stroke: $red;
    }

    .ant-progress-text {
      color: $white;
    }
  }

  .ant-progress-status-normal {
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: $green;
    }

    .ant-progress-inner {
      background: transparent;
    }

    .ant-progress-text {
      @include typo-24-normal();
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: $white;
    font-size: 3.8rem;
  }

  .ant-progress-status-success {
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: $green;
    }

    .ant-progress-inner {
      background: $green;
    }

    .ant-progress-circle-trail {
      stroke: $green;
    }

    .ant-progress-text {
      color: $white;
      font-size: 3.8rem;
    }
  }
}
