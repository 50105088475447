@import 'scss/common/vars';
@import 'scss/common/typography';

.limit-form {
  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
    }

    &__info:first-of-type {
      margin-bottom: 20px;

      @media only screen and (min-width: $tabletD) {
        margin-bottom: 0;
      }
    }

    h5 {
      color: $grey2;

      @include typo-14-normal();
    }

    h3 {
      display: inline-block;
      color: $purple;

      @include typo-20-medium();
    }

    small {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 16px;
      color: $grey2;

      @include typo-14-normal();

      .anticon {
        margin-right: 8px;
        color: $grey2;
        font-size: 12px;
      }
    }
  }
}
