@import 'scss/common/vars';
@import 'scss/common/typography';
@import 'js/views/Header/hamburger';
@import 'js/views/Header/menu';

.header {
  width: 100%;
  background: $purple;
  color: $white;

  &__inside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1136px;
    margin: 0 auto;
    padding: 14px 16px;

    @media only screen and (max-width: $tablet) {
      padding: 15px 21px;
    }

    &--right {
      display: flex;
      align-items: center;
      text-align: center;

      @include typo-14-medium();

      a {
        margin-right: 25px;
        margin-bottom: 0;
        transition: opacity 0.3s ease-in-out;
        color: $white;
        text-decoration: none;

        &:hover {
          opacity: 0.8;
          color: $white;
        }
      }

      a,
      .icon {
        @media only screen and (max-width: $tablet) {
          display: none;
        }
      }

      .submit-btn {
        display: block;
        margin: 0;
        transition: opacity 0.3s ease-in-out;

        @media only screen and (max-width: $tablet) {
          display: none;
        }

        &:hover {
          opacity: 0.8;
          background: transparent;
        }
      }
    }

    &--left {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        display: none;
        margin-left: 40px;
        transition: opacity 0.3s ease-in-out;
        color: $white;

        @include typo-16-normal();

        @media only screen and (min-width: $tablet) {
          display: block;
        }

        &:hover {
          opacity: 0.8;
          color: $white;
        }

        &.active {
          @include typo-16-bold();
        }
      }

      .icon {
        @media only screen and (min-width: $tablet) {
          margin-right: 70px;
        }

        svg {
          display: block;

          @media only screen and (max-width: $tablet) {
            width: auto;
            height: 26px;
          }
        }
      }
    }
  }
}
