@import 'scss/common/vars';
@import 'scss/common/typography';
@import 'js/components/Buttons/styles.scss';

.info-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  padding: 14px 0;

  @media only screen and (min-width: $tabletD) {
    justify-content: flex-start;
    padding: 12px 32px;
    border-radius: 8px;
  }

  h3 {
    margin-bottom: 0;

    @include typo-14-normal();

    span {
      @include typo-18-medium();
    }
  }

  .anticon svg {
    width: 24px;
    height: 21px;
    margin-right: 16px;
  }

  &--loading {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    padding: 16px;
    border-top: 1px solid $purple;
    border-bottom: 1px solid $purple;
    background: $light-purple;
    color: $black;

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
      padding: 12px 32px;
      border: 1px solid $purple;
      border-radius: 8px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      @include typo-16-medium();
      text-align: center;

      @media only screen and (min-width: $tabletD) {
        text-align: left;
      }
    }

    .anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin-right: 24px;
      border-radius: 50%;
      background: $purple;
      color: $white;

      svg {
        margin-right: 0;
      }
    }

    .submit-btn {
      display: flex;
      align-items: center;
      margin: 0;

      .anticon {
        margin-right: 0;
      }
    }
  }

  &--expiration {
    border: 1px solid $purple;
    background: $purple;
    color: $white;

    h3 {
      color: $white;
    }

    @media only screen and (min-width: $tabletD) {
      justify-content: flex-end;
      padding: 12px 40px;
    }
  }

  &--error {
    flex-wrap: wrap;
    padding: 15px;
    border: 1px solid $red;
    background: $light-red;
    color: $black;

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
      padding: 12px 32px;
    }

    h3 {
      text-align: center;

      @media only screen and (min-width: $tabletD) {
        text-align: left;
      }
    }

    .anticon {
      display: none;
      width: 32px;
      height: 32px;
      margin-right: 24px;
      color: $white;

      @media only screen and (min-width: $tabletD) {
        display: initial;
      }

      svg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $red;

        path:first-of-type {
          color: $red;
        }
      }
    }

    .submit-btn {
      margin-top: 12px;
      margin-left: 0;

      @media only screen and (min-width: $tabletD) {
        margin-top: 0;
        margin-left: 43px;
      }

      .anticon {
        svg {
          background: transparent;

          path:first-of-type {
            color: $white;
          }
        }
      }
    }
  }

  &--error-big {
    flex-direction: column;
    margin-bottom: 32px;
    padding: 30px 20px 24px 20px;
    text-align: center;

    @media only screen and (min-width: $tabletD) {
      padding: 30px 120px 24px 120px;
    }

    h3 {
      text-align: center;

      @include typo-18-medium();
    }

    .submit-btn {
      display: flex;
      align-items: center;
      margin-top: 18px;
      margin-left: 0;
    }
  }
}
