@import 'scss/common/vars';
@import 'scss/common/typography';

.factoring-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.6rem;
  padding: 1rem 1.6rem;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 15px 15px 5px rgba(194, 186, 228, 0.05);

  @media only screen and (min-width: $tabletD) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 2.4rem 4rem;
  }

  &__logo {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    margin-bottom: 30px;
    padding-top: 20px;
    text-align: center;

    @media only screen and (min-width: $tabletD) {
      width: 165px;
      max-width: 165px;
      height: 58px;
      margin-right: auto;
      margin-bottom: 0;
      padding-top: 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__info {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 30px;
    padding: 0 4rem;
    list-style: none;
    text-align: center;

    li {
      min-width: 150px;
      margin-bottom: 14px;
      padding: 0 1rem;

      @media only screen and (min-width: $tabletD) {
        margin-bottom: 0;
      }
    }

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 602px;
      margin: 0;
    }
  }

  &__btn-wrapper {
    a {
      @include typo-14-normal();
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (min-width: $tabletD) {
      max-width: 165px;
      margin-left: auto;
    }
  }

  &--applied {
    border: 3px solid $green;
  }
}
