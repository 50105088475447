@import 'scss/common/vars';
@import 'scss/common/typography';

.raport-rating {
  &__boxes.ant-col-md-8 {
    @media only screen and (min-width: $tabletD) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .box {
      &:first-of-type {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-radius: 0;
        background: $purple;
        text-align: center;

        @media only screen and (min-width: $tabletD) {
          height: calc(50% - 26px);
          border-radius: 8px;
          background: $white;
        }

        .box__inner {
          padding: 48px 0;

          @media only screen and (min-width: $tabletD) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: stretch;
            align-items: stretch;
            justify-content: space-between;
            padding: 32px 0;
          }

          h4 {
            max-width: 200px;
            color: $white;

            @include typo-18-medium();

            @media only screen and (min-width: $tabletD) {
              max-width: 100%;
              margin-bottom: 0;
              color: $black;

              @include typo-20-medium();
            }
          }

          h2 {
            color: $white;

            @include typo-24-medium();

            @media only screen and (min-width: $tabletD) {
              margin-bottom: 0;
              color: $purple;
              font-size: 3.4rem;
            }
          }
        }
      }

      &:nth-of-type(2) {
        display: none;

        @media only screen and (min-width: $tabletD) {
          position: relative;
          height: calc(50% + 8px);
        }

        .box__inner {
          @media only screen and (min-width: $tabletD) {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: stretch;
            align-items: stretch;
            justify-content: center;
            padding: 32px 0;
          }
        }

        @media only screen and (min-width: $tabletD) {
          display: flex;
          text-align: center;

          a {
            display: block;
            transition: opacity 0.3s ease-in-out;
            font-weight: 500;

            &:hover {
              opacity: 0.6;
              color: $purple;
            }
          }
        }

        h4 {
          @include typo-20-medium();
          margin-bottom: 24px;
        }

        p {
          margin-bottom: 0;
        }

        .icon {
          position: absolute;
          top: -40px;
          right: -40px;
          width: 140px;
          height: 126px;

          svg {
            width: 126px;
            height: 126px;
          }
        }
      }

      &.error {
        border: 1px solid $red;
        background: $light-red;
      }
    }
  }
}
