@import 'scss/common/vars';
@import 'scss/common/typography';

.footer {
  width: 100%;
  background: $purple;
  color: $white;

  &__inside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1136px;
    margin: 0 auto;
    padding: 58px 16px;

    @media only screen and (max-width: $tablet) {
      flex-direction: column;
      align-items: flex-start;
      padding: 32px 24px;
    }

    &--left {
      .icon,
      svg {
        display: block;
        width: 240px;
        height: auto;

        @media only screen and (max-width: $tablet) {
          width: auto;
          height: 26px;
        }
      }
    }

    &--right {
      @include typo-20-normal();

      @media only screen and (max-width: $tablet) {
        @include typo-14-normal();
      }

      p {
        margin-bottom: 24px;

        @media only screen and (max-width: $tablet) {
          margin-top: 32px;
          margin-bottom: 20px;
        }
      }

      > div {
        display: flex;
        align-items: center;
        text-align: center;

        a {
          margin-bottom: 0;
          margin-left: 30px;
          transition: opacity 0.3s ease-in-out;
          color: $white;
          text-align: left;
          text-decoration: none;

          @media only screen and (max-width: $tablet) {
            @include typo-16-medium();
          }

          &:hover {
            opacity: 0.8;
            color: $white;
          }
        }
      }
    }
  }
}
