@import 'scss/common/vars';

.address-form {
  @media only screen and (min-width: $tabletD) {
    .ant-form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: stretch;
      align-items: stretch;
      justify-content: space-between;

      .ant-row {
        width: calc(50% - 12px);

        &:first-of-type {
          width: 100%;
        }
      }
    }
  }
}
