@import 'scss/common/vars';

.new-password-form {
  &__buttons {
    .button {
      margin-bottom: 0;
    }

    @media only screen and (min-width: $tabletD) {
      margin-top: 64px;
    }
  }
}
