@import 'scss/common/vars';

.cancel-update {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  background: $white3;

  @media only screen and (max-width: $tabletD) {
    div {
      flex-direction: column;
    }
  }
}
