@import 'scss/common/vars';
@import 'scss/common/typography';

.ant-modal-root {
  .expired-modal.ant-modal {
    top: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: none;

    @media screen and (min-width: $tabletD) {
      top: 100px;
      height: 394px;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    }

    .ant-modal-close-x {
      display: block;
      color: $text-dark;
      font-size: 24px;

      @media screen and (min-width: $tabletD) {
        font-size: 18px;
      }
    }

    .ant-modal-body,
    .ant-modal-footer {
      padding: 0;
      background: transparent;
    }

    .box {
      top: 0;
      bottom: 0;
      height: 540px;
      margin: auto;
      box-shadow: none;

      @media screen and (min-width: $tabletD) {
        height: 394px;
      }

      &__inner--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        border-radius: 8px;
        text-align: center;
      }
    }

    h3 {
      @include typo-28-bold();
      margin-bottom: 0;
      color: $purple;
    }

    p {
      @include typo-18-medium();
      margin: 0;
    }

    .btn-wrapper {
      margin: 0;

      button {
        width: 100%;

        span {
          @include typo-14-normal();
        }

        @media screen and (min-width: $tabletD) {
          width: auto;
        }
      }
    }
  }
}
