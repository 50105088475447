@import 'scss/common/vars';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  &--low {
    min-height: auto;
  }

  &--noBg {
    position: static;
    background: transparent;
    box-shadow: none;
    text-align: center;
  }

  &__info {
    color: $purple;
    font-size: 20px;
    text-align: center;
  }

  & &__spin {
    position: relative;
  }
}
