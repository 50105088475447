@import 'scss/common/vars';

.sw-reload {
  display: flex;
  position: fixed;
  z-index: 10;
  z-index: 99999;
  top: 83px;
  right: 80px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 120px;
  padding: 10px;
  border-radius: 4px;
  background: $white;
  box-shadow: 0 15px 30px 0 #0000001a;
  color: $white;
  font-weight: 400;
  text-align: center;

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;

    button {
      width: 50%;
      min-width: auto;
      padding: 5px;
      border: none;
      text-transform: uppercase;

      span {
        font-size: 1.3rem;
      }

      &:nth-of-type(2) {
        background: $purple;
        color: $white;
      }
    }
  }

  h4 {
    display: block;
    width: 100%;
    margin-left: 20px;
    padding-top: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    font-size: 1.6rem;
    text-align: left;
  }
}
