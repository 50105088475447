@import 'scss/common/vars';
@import 'scss/common/typography';

.loader-wrapper {
  margin-top: 16px;
}

.smeo-limits {
  > h1 {
    width: 100%;
    margin-bottom: 12px;
    color: $purple;
    text-align: center;

    @include typo-32-medium();
  }

  > h2 {
    width: 100%;
    margin-bottom: 56px;
    color: $text;
    text-align: center;

    @include typo-24-normal();
  }
}

.smeo-consents {
  .ant-form-item {
    margin-bottom: 0;
  }

  h2 {
    @include typo-24-medium();
    margin-bottom: 24px;
    color: $purple;
  }

  h6 {
    @include typo-16-normal();
    margin-top: 40px;
    margin-bottom: 0;
    color: $purple;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div {
      > h5 {
        @include typo-14-normal();
        display: block;
        width: 100%;
        margin: 0;
        padding-bottom: 12px;
        color: $grey2;
      }

      > h3 {
        @include typo-20-medium();
        display: block;
        width: 100%;
        margin: 0;
        color: $black;
      }
    }
  }

  .loader {
    margin-top: 16px;
  }

  .ant-checkbox-wrapper {
    @media only screen and (max-width: $tabletD) {
      margin-bottom: 30px;
    }
  }
}

.smeo-loader {
  .box__inner--content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon-redo {
    svg {
      width: 60px;
      height: 60px;
      margin: auto;
      fill: $purple;
    }
  }

  .ant-result-icon {
    color: $red;
  }
}

.smeo-finish {
  .ant-result-title {
    margin: 0 0 20px;
    color: $purple;
    font-weight: 600;
  }

  .ant-result-subtitle {
    color: $text-dark;
    font-size: 16px;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #35ca79;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    font-size: 16px;
  }

  .ant-result {
    @media only screen and (max-width: $tabletD) {
      padding: 0;
    }
  }
}

.smeo-error {
  h2 {
    display: block;
    width: 100%;
    text-align: center;
  }
}
