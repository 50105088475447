@import 'scss/common/vars';

.consent {
  position: relative;

  .ant-form-item {
    margin: 0 0 10px;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    margin: 0;
    line-height: 22px;
  }

  .ant-checkbox {
    & + span {
      padding: 0 8px 0 16px;
    }
  }

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-checkbox-wrapper {
    margin-left: 15px;
  }

  &.consent--required {
    &::before {
      content: '*';
      position: absolute;
      margin-left: 2px;
      color: #ff4d4f;
      font-family: SimSun, sans-serif;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2;
    }
  }
}
