@import 'scss/common/vars';
@import 'scss/common/typography';

.admin-search {
  .ant-form {
    display: block;
    padding-bottom: 2rem;

    @media (min-width: 576px) {
      display: flex;
      justify-content: space-between;

      .ant-form-item {
        width: 100%;
      }
    }

    .ant-btn {
      align-self: flex-end;
      margin-bottom: 32px;
      padding: 0;
    }
  }

  &__report-date {
    margin: 50px 0 0;
    color: $text-dark;
    font-size: 16px;
  }

  &__company-name {
    margin: 0 0 10px;
    color: $purple;
  }

  &__btn-wrapper {
    margin: 0;
  }

  &__user-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;

    p {
      @include typo-16-medium();

      span {
        color: $purple;
      }
    }
  }

  &__empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;

    h4 {
      @include typo-20-medium();
      color: $light-purple;
    }
  }

  &__table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  table {
    display: table;
    width: 100%;

    thead {
      width: 100%;
      background: $white3;

      th {
        padding: 16px;
        text-align: left;

        @include typo-12-normal();
      }
    }

    tbody {
      tr {
        .dot {
          display: block;
          width: 12px;
          height: 12px;
          margin: auto;
          border-radius: 50%;

          &.red {
            background: $red;
          }

          &.yellow {
            background: $yellow;
          }

          &.green {
            background: $green;
          }
        }
      }

      td {
        padding: 16px;
        border-bottom: 1px solid $white3;

        @include typo-14-normal();
      }
    }
  }
}
