@import 'scss/common/vars';
@import 'scss/common/typography';

.settings-forms {
  padding-bottom: 120px;
}

.settings-form {
  margin-bottom: 15px;
  padding: 0 2.4rem;

  @media only screen and (min-width: $tabletD) {
    padding: 0 4.8rem;
  }

  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-input-affix-wrapper {
    padding: 16px 12px;
    border: 0;
    border-radius: 2px;
    background: $white2;
  }

  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover,
  .ant-form-item-has-error .ant-input {
    background: $white2;
  }

  .ant-form-item {
    margin-bottom: 32px;
  }

  .ant-input-suffix {
    .anticon {
      color: $light-purple;
    }
  }

  .btn-wrapper {
    justify-content: flex-end;
    margin-top: 30px;

    &.center {
      justify-content: center;
    }
  }

  &__space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-form-item {
      width: 200px;
    }
  }

  h2 {
    margin-bottom: 3.2rem;
    color: $purple;

    @include typo-24-bold();
  }

  .box__inner--content {
    padding-top: 32px;
  }
}
