@import 'scss/common/vars';
@import 'scss/common/typography';

.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 90px;
    height: 90px;

    @media screen and (min-width: $tabletD) {
      width: 118px;
      height: 118px;
    }
  }

  &__description {
    margin-top: 64px;
    text-align: center;

    @media screen and (min-width: $tabletD) {
      margin-top: 48px;
    }

    h1 {
      margin-bottom: 0;
      color: $purple;
      line-height: 4rem;

      @include typo-24-bold();

      @media screen and (min-width: $tabletD) {
        display: initial;

        @include typo-32-bold();
      }
    }

    h3 {
      @include typo-20-normal();
      line-height: 3.8rem;

      @media screen and (min-width: $tabletD) {
        display: initial;

        @include typo-24-normal();
      }
    }
  }

  &__button-wrapper {
    margin-top: 64px;

    @media screen and (min-width: $tabletD) {
      margin-top: 48px;
    }
  }
}
