@import 'scss/common/vars';
@import 'scss/common/typography';

.report-offers {
  padding: 2.4rem 1.6rem;

  @media only screen and (min-width: $tabletD) {
    padding: 1.6rem 0;
  }
}

.report-box {
  margin-bottom: 1.6rem;
  padding: 0 1.6rem;
  text-align: center;

  @media only screen and (min-width: $tabletD) {
    padding: 0 4rem;
  }

  .box__inner {
    padding: 1.6rem 0;

    @media only screen and (min-width: $tabletD) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 100%;
      padding: 1.2rem 0;
    }
  }

  img {
    width: auto;
    height: 32px;

    @media only screen and (min-width: $tabletD) {
      top: 0;
      bottom: 0;
      max-width: 165px;
      margin-top: auto;
      margin-bottom: auto;
      object-fit: contain;
      object-position: center;
    }
  }

  .report-box__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 0;
    list-style: none;

    @media only screen and (min-width: $tabletD) {
      width: calc(100% - 400px);
      margin: 0;
    }

    &.active {
      li > .desktop-only {
        display: none;

        @media only screen and (min-width: $tabletD) {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-top: 32px;
        }

        span {
          color: $black;
        }
      }
    }

    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 8px 0;

      @media only screen and (min-width: $tabletD) {
        flex-direction: column;
        flex-wrap: nowrap;
        width: calc(100% / 3);
        height: 100%;
      }

      .desktop-only {
        display: none;
      }

      span {
        &.purple {
          color: $purple;
        }

        &.yellow,
        &.none {
          color: $yellow;
        }

        &.red {
          color: $red;
        }

        &.green {
          color: $green;
        }
      }

      h4 {
        @include typo-14-normal();
        display: block;
        width: 100%;
        margin-bottom: 6px;
      }

      &:nth-child(1) {
        span {
          @include typo-14-medium();

          @media only screen and (min-width: $tabletD) {
            @include typo-20-medium();
          }
        }
      }

      &:nth-child(2) {
        border-right: 1px solid $grey;
        border-left: 1px solid $grey;

        span {
          @include typo-16-medium();

          @media only screen and (min-width: $tabletD) {
            @include typo-20-medium();
          }
        }
      }

      &:nth-child(3) {
        span {
          @include typo-16-medium();

          @media only screen and (min-width: $tabletD) {
            @include typo-20-medium();
          }
        }
      }
    }
  }

  &__details-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;

    @media only screen and (min-width: $tabletD) {
      flex-direction: column;
      margin-top: 0;
      padding-top: 12px;
    }
  }

  &__details-btn {
    position: relative;
    padding: 0;
    padding-right: 16px;
    border: 0;
    background: transparent;
    cursor: pointer;

    @include typo-14-normal();

    @media only screen and (min-width: $tabletD) {
      order: 1;
      padding: 12px;
      padding-right: 24px;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 6px;
      height: 6px;
      margin: auto;
      transform: rotate(45deg);
      border-right: 1px solid $black;
      border-bottom: 1px solid $black;
    }

    &.active {
      &::after {
        transform: rotate(-135deg);
      }
    }
  }

  &__offer-btn {
    padding: 8px 12px;
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 2px;
    background: $purple;
    color: $white;

    @include typo-14-normal();

    @media only screen and (min-width: $tabletD) {
      order: 0;
    }

    &:hover,
    &:focus {
      background-color: $dark-purple;
      color: $white;
    }
  }

  &__details {
    width: calc(100% + 32px);
    margin-top: 16px;
    margin-left: -16px;
    padding: 0;
    list-style: none;

    @media only screen and (min-width: $tabletD) {
      display: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      padding: 0 16px;
      box-shadow: inset 0 -1px 0 #eee7f4;

      h4 {
        @include typo-14-normal();
      }

      span {
        @include typo-14-medium();
      }
    }
  }

  &--applied {
    border: 3px solid $green;
  }
}
