@import 'scss/common/vars';

.smeo-bank {
  &__title {
    margin: 0 0 24px;
    color: $purple;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  &__subtitle {
    margin: 0 0 32px;
    color: $grey2;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  &__select-title {
    color: $purple;
    font-size: 16px;

    &--margin {
      margin-top: 20px;
    }
  }

  .ant-select.small.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }

  &__btn-wrapper {
    margin-top: 24px;
  }
}
