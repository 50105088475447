.overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  border: 0;
  outline: none;
  background: rgba(0, 0, 0, 0.35);

  &:focus,
  &:hover {
    outline: none;
  }
}

.menu {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  width: 214px;
  height: 100vh;
  padding: 20px 16px;
  transform: translateX(214px);
  transition: transform 0.3s ease-in-out;
  background: $purple;

  &.open {
    transform: translateX(0);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  ul {
    margin-bottom: 100px;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 37px;
      text-align: right;

      button,
      a {
        min-height: auto;
        margin: 0;
        padding-right: 0;
        color: $white;
        text-align: right;

        @include typo-16-normal();

        &:hover {
          background: transparent;
        }
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    > a {
      margin-top: 16px;
      color: $white;
      text-align: right;

      @include typo-14-medium();

      span {
        @include typo-14-normal();
        display: block;
        padding-top: 16px;
      }
    }
  }

  &__close {
    width: 100%;
    margin-bottom: 70px;
    border: 0;
    background: transparent;
    text-align: right;
    cursor: pointer;
  }
}
