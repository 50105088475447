@import 'scss/common/vars';
@import 'scss/common/typography';

.check-data {
  position: relative;

  @media only screen and (min-width: $tabletD) {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    width: 100%;
    margin-bottom: 24px;

    @media only screen and (min-width: $tabletD) {
      width: 50%;
    }

    h4 {
      @include typo-16-medium();
      margin-bottom: 1.6rem;
      color: $grey2;
    }

    h3 {
      @include typo-20-medium();
      color: $text;
    }
  }
}
