@import 'scss/common/vars';
@import 'scss/common/typography';

.personal-form {
  .ant-form-item {
    width: 100%;
    margin-bottom: 15px;

    @media only screen and (min-width: $tabletD) {
      width: auto;
    }
  }

  &__icon-text {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 22px;
    color: $grey2;
    line-height: 16px;

    @include typo-14-normal();

    @media only screen and (min-width: $tabletD) {
      margin-bottom: 40px;
    }

    .anticon {
      display: block;
      width: auto;
      height: 19px;
      margin-right: 10px;
      color: $light-purple;

      svg {
        height: 20px;
      }
    }
  }

  &__certyficates {
    margin-top: 32px;

    @media only screen and (min-width: $tabletD) {
      text-align: center;
    }

    h5 {
      margin-bottom: 24px;
      color: $purple;

      @include typo-14-normal();
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (min-width: $tabletD) {
        justify-content: center;
      }

      a {
        max-width: calc(50% - 15px);

        &:nth-child(2) {
          margin-left: 30px;

          @media only screen and (min-width: $tabletD) {
            margin-left: 70px;
          }
        }

        img {
          width: auto;
          max-height: 54px;
        }
      }
    }
  }

  &__pickers {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    @media only screen and (min-width: $tabletD) {
      flex-wrap: nowrap;
    }

    .ant-picker {
      @media only screen and (min-width: $tabletD) {
        margin-right: 24px;
      }
    }
  }
}
