@import 'scss/common/vars';
@import 'scss/common/typography';

.report-desc {
  &__heading {
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding: 20px 10px;
    background: $white;
    color: $purple;
    text-align: center;

    @include typo-18-medium();

    @media only screen and (min-width: $tabletD) {
      margin-top: 56px;
      margin-bottom: 24px;
      padding: 0;
      background: transparent;
      color: $black;

      @include typo-24-medium();
    }
  }

  &__description {
    display: block;
    margin: 0;
    padding: 24px 16px 32px 16px;
    color: $text-dark;
    text-align: center;

    @include typo-14-normal();

    @media only screen and (min-width: $tabletD) {
      @include typo-16-normal();
      padding: 0 40px 32px 40px;
      // background: transparent;
    }
  }
}
