@import 'scss/common/vars.scss';
@import 'scss/common/typography.scss';

.custom-steps {
  overflow: hidden;

  &.ant-steps {
    width: 100%;
    max-width: 520px;
    margin: auto;
    padding-bottom: 56px;
  }

  .icon {
    display: block;
    width: 32px;
    height: 24px;
  }

  .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    width: 32px;
  }

  .ant-steps-item-finish > .ant-steps-item-container {
    > .ant-steps-item-tail::after {
      margin-left: 20px;
      background: $purple;
    }

    > .ant-steps-item-content {
      margin-top: 10px;

      @media only screen and (min-width: $mobile) {
        margin-top: 20px;
      }

      & > .ant-steps-item-title {
        color: $purple;
        line-height: 14px;

        @include typo-12-medium();

        @media only screen and (min-width: $tabletD) {
          @include typo-14-medium();
        }
      }
    }
  }

  .ant-steps-item-process,
  .ant-steps-item-wait > .ant-steps-item-container {
    > .ant-steps-item-tail::after {
      margin-left: 20px;
      opacity: 0.3;
      background: $purple;
    }

    > .ant-steps-item-icon > .ant-steps-icon .icon {
      opacity: 0.3;
    }

    > .ant-steps-item-content {
      margin-top: 10px;

      @media only screen and (min-width: $mobile) {
        margin-top: 20px;
      }

      & > .ant-steps-item-title {
        opacity: 0.3;
        color: $purple;
        line-height: 14px;

        @include typo-12-medium();

        @media only screen and (min-width: $tabletD) {
          @include typo-14-medium();
        }
      }
    }
  }

  .ant-steps-item-tail {
    width: calc(100% - 40px);

    @media only screen and (max-width: $mobile) {
      width: 100%;

      &::after {
        width: 50%;
      }
    }
  }
}

.ant-steps-label-vertical {
  .ant-steps-item {
    @media only screen and (max-width: $mobile) {
      max-width: calc(100% / 3);
      margin-left: initial;
    }
  }

  .ant-steps-item-icon {
    @media only screen and (max-width: $mobile) {
      margin: initial !important;
    }
  }
}

.ant-steps-item-container {
  @media only screen and (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
