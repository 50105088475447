@import 'common/index';

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#root,
body {
  height: 100%;
}

.wrapper {
  min-height: calc(100vh - 54px);
  background: $grey;

  @media only screen and (min-width: $tabletD) {
    min-height: calc(100vh - 72px);
  }

  &.white {
    background: $white;
  }

  &.wide {
    .container {
      max-width: 1136px;
      padding: 0;
      overflow-x: hidden;

      @media only screen and (min-width: $tabletD) {
        padding: 5.2rem 1.6rem 0;
      }
    }
  }

  &.no-flex > .container {
    display: block;
    padding: 5.2rem 1.6rem 0;
    padding-bottom: 80px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 752px;
  height: 100%;
  margin: auto;
  padding: 5.2rem 1.6rem 0;
  padding-bottom: 100px;
}
