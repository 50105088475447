@import 'scss/common/vars';

.transaction-info {
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;

    @media only screen and (min-width: $mobile) {
      flex-direction: row;
      margin: 0 auto 56px;
      font-size: 24px;
    }

    .anticon {
      width: auto;
      margin: 0 0 12px;
      color: $light-purple;

      svg {
        width: 18px;
        height: 21px;
      }

      @media only screen and (min-width: $mobile) {
        margin: 0 32px 0 0;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    margin: 16px 0 0;
    color: $text-dark;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    @media only screen and (min-width: $mobile) {
      margin: 48px 0 0;
    }
  }
}
