@import 'scss/common/vars';
@import 'scss/common/typography.scss';

.provider {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  span {
    @include typo-14-normal();
    margin-right: 18px;
    color: $grey2;
  }

  img {
    display: block;
    width: 112px;
  }
}
