@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import 'vars';

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-size: 1.5rem;
}

b,
strong {
  font-weight: bold;
}

a {
  color: $purple;
}

* {
  font-family: Roboto, sans-serif;
}
