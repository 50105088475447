@import 'scss/common/vars';

.login-form {
  .login-form__link {
    color: $text-dark;
    font-weight: 600;
    text-decoration: underline;
  }

  &__buttons {
    margin-bottom: 24px;

    @media only screen and (min-width: $tabletD) {
      margin-top: 64px;
    }

    .button {
      margin-bottom: 0;
    }
  }

  &__text {
    color: $text-dark;
    text-align: center;

    @media only screen and (min-width: $tabletD) {
      &--left {
        text-align: left;
      }
    }
  }
}
