@import 'scss/common/vars';
@import 'scss/common/typography';

.smeo-regulamin {
  h3 {
    @include typo-18-normal();
    color: $text-dark;
    text-align: center;

    span {
      display: block;
      width: 100%;
      padding: 20px 0;
      color: $purple;

      @include typo-20-medium();
    }

    a {
      color: $purple;
      text-decoration: underline;
    }
  }
}
