@import 'scss/common/vars';
@import 'scss/common/typography';

.rating-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  .box__inner--content {
    max-width: 610px;
  }

  .ant-progress {
    .ant-progress-text {
      color: $black;
      font-size: 5.2rem;

      span {
        opacity: 0.55;
      }
    }
  }

  h3 {
    @include typo-20-medium();

    @media only screen and (min-width: $tabletD) {
      @include typo-26-medium();
    }
  }

  p,
  button {
    display: block;
    max-width: 254px;
    margin: auto;
    color: $text-dark;

    @include typo-14-normal();

    @media only screen and (min-width: $tabletD) {
      max-width: 100%;
    }
  }

  p {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  button {
    border: 0;
    background: transparent;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $purple;
    }
  }

  ul {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: left;

    li {
      display: flex;
      position: relative;
      align-items: center;
      width: calc(100% / 3);
      margin-bottom: 20px;
      padding-left: 25px;

      @include typo-14-medium();

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 13px;
        height: 13px;
        margin: auto;
        border-radius: 50%;
      }

      &.error::after {
        background: $red;
      }

      &.warning::after {
        background: $yellow;
      }

      &.success::after {
        background: $green;
      }
    }

    @media only screen and (min-width: $tabletD) {
      display: flex;
    }
  }

  &--success {
    .ant-progress .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: $green;
    }

    h3 > span {
      color: $green;
    }
  }

  &--warning {
    .ant-progress .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: $yellow;
    }

    h3 > span {
      color: $yellow;
    }
  }

  &--error {
    .ant-progress .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: $red;
    }

    h3 > span {
      color: $red;
    }
  }

  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: $black;
  }
}
