@import 'scss/common/vars';
@import 'scss/common/typography';

.confirm-data {
  @media only screen and (min-width: $tabletD) {
    padding-left: 54px;
  }

  &__info {
    margin-bottom: 52px;

    h4 {
      @include typo-16-medium();
      margin-bottom: 20px;
      color: $grey2;
    }

    h3 {
      @include typo-20-medium();
      margin-bottom: 20px;
      color: $text;

      span {
        color: $grey2;
      }
    }

    h5 {
      @include typo-14-normal();
      margin-bottom: 5px;
      color: $text;
    }

    &__columns {
      h3 {
        color: $purple;
      }

      @media only screen and (min-width: $tabletD) {
        display: flex;

        > div:first-of-type {
          padding-right: 84px;
        }
      }
    }
  }
}
