@import 'scss/common/vars';

.consent-form {
  .ant-divider {
    margin: 29px 0 32px;
  }

  &__button-wrapper {
    margin-top: 36px;
  }
}
