@import 'scss/common/vars';

.sms-input {
  display: flex;
  justify-content: center;

  &__wrapper {
    display: inline-flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media only screen and (min-width: $mobile) {
      width: 288px;
    }
  }

  &__box {
    display: flex;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    max-width: 100%;
    height: 54px;
    border: 1px solid #efedf8;
    border-radius: 2px;
    background-color: #fafafa;
    color: $purple;
    font-size: 1.6rem;

    &--error {
      border-color: #ff4d4f;
      color: #ff4d4f;
    }
  }

  &__focus-wrapper {
    position: absolute;
    top: 0;
  }

  &__focus {
    width: 54px;
    height: 54px;
    border-radius: 2px;

    &--active {
      border: 1px solid $light-purple;
    }
  }

  &__error {
    display: block;
    position: absolute;
    bottom: -18px;
    left: 12px;
    max-width: 312px;
    margin: 0 auto;
    color: #ff4d4f;
  }
}
