@import 'scss/common/vars';
@import 'scss/common/typography';

.consents-form {
  &__checkbox {
    margin-top: 16px;
    padding: 0 40px;

    .box__inner--content {
      max-width: 720px;
      padding: 32px 0;
    }

    &__content {
      max-height: 80px;
      padding-right: 16px;
      overflow-y: auto;
      color: $text-dark;

      @include typo-14-normal();

      a {
        color: $purple;
      }
    }

    .ant-form-item {
      position: relative;
      padding-left: 16px;
    }

    &--required {
      .ant-form-item::before {
        content: '*';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        color: #ff4d4f;
        line-height: 2.6;

        @include typo-14-normal();
      }
    }
  }

  &__button-wrapper {
    display: flex;
    position: sticky;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 38px 20px;
    border-radius: 8px 8px 0 0;
    background: $white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
}
