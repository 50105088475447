@import 'scss/common/vars';
@import 'scss/common/typography';

.report-heading {
  padding: 32px 16px 0 16px;
  background: $white;
  text-align: center;

  @media only screen and (min-width: $tabletD) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 34px;
    padding: 0;
    background: transparent;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      margin-right: 8px;
    }
  }

  h3 {
    @include typo-16-normal();
    margin-bottom: 16px;

    @media only screen and (min-width: $tabletD) {
      margin-bottom: 0;
      line-height: 36px;
    }

    span {
      color: $purple;

      @include typo-16-medium();
    }
  }

  .ant-select {
    border: 1px solid $purple;
    border-radius: 2px;
  }

  .ant-select-single.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 32px;
      background: $white;
    }
  }

  .ant-select-single.ant-select:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 32px;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    height: 32px;
  }
}
