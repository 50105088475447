@import 'scss/common/vars';

.hamburger {
  display: none;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 22px;
  height: 18px;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  @media only screen and (max-width: $tablet) {
    display: flex;
  }

  span {
    width: 100%;
    height: 2px;
    transition: transform 0.3s ease-in-out;
    background: $white;
  }

  &.active {
    span {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
