@mixin typo-12-normal() {
  font-family: Roboto, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

@mixin typo-12-medium() {
  font-family: Roboto, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}

@mixin typo-14-normal() {
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
}

@mixin typo-14-medium() {
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

@mixin typo-14-bold() {
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
}

@mixin typo-16-normal() {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

@mixin typo-16-medium() {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

@mixin typo-16-bold() {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
}

@mixin typo-18-normal() {
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
}

@mixin typo-18-medium() {
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
}

@mixin typo-18-bold() {
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
}

@mixin typo-20-normal() {
  font-family: Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

@mixin typo-20-medium() {
  font-family: Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

@mixin typo-20-bold() {
  font-family: Roboto, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

@mixin typo-24-normal() {
  font-family: Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
}

@mixin typo-24-medium() {
  font-family: Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
}

@mixin typo-24-bold() {
  font-family: Roboto, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}

@mixin typo-26-normal() {
  font-family: Roboto, sans-serif;
  font-size: 2.6rem;
  font-weight: 400;
}

@mixin typo-26-medium() {
  font-family: Roboto, sans-serif;
  font-size: 2.6rem;
  font-weight: 500;
}

@mixin typo-26-bold() {
  font-family: Roboto, sans-serif;
  font-size: 2.6rem;
  font-weight: 700;
}

@mixin typo-28-bold() {
  font-family: Roboto, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
}

@mixin typo-32-medium() {
  font-family: Roboto, sans-serif;
  font-size: 3.2rem;
  font-weight: 500;
}

@mixin typo-32-bold() {
  font-family: Roboto, sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
}
