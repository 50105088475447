@import 'scss/common/vars';
@import 'scss/common/typography';

.summary-box {
  margin-bottom: 16px;
  padding: 0 1.6rem;
  border-radius: 4px;
  text-align: center;

  .box__inner {
    height: 100%;
    padding: 1.6rem 0;
  }

  @media only screen and (min-width: $tabletD) {
    height: 100%;
    margin-bottom: 0;

    .box__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  h3 {
    @include typo-20-medium();
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 8px;
    color: $text-dark;

    @include typo-14-medium();
  }

  &__stripe {
    content: '';
    display: block;
    width: 100%;
    height: 9px;
    margin-bottom: 16px;
    border-radius: 8px;
    background: $yellow;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;

    .grade {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        margin: 0;
        color: $text-dark;
        text-align: left;

        @include typo-14-medium();

        span {
          display: block;
          width: 100%;
          color: $yellow;
        }
      }

      .ant-result {
        padding: 0;
        padding-right: 12px;

        .ant-result-icon {
          margin-bottom: 0;
        }

        .anticon,
        svg {
          width: 36px;
          height: 36px;
        }
      }
    }

    .indicator {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 140px;

      p {
        margin-bottom: 0;
        color: $text-dark;
        text-align: left;

        @include typo-14-medium();
      }

      > div {
        padding-right: 0;

        .anticon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .anticon,
        svg {
          width: auto;
          height: 34px;
          color: $text-dark;
        }

        .number {
          padding-right: 12px;
          color: $text-dark;
          font-size: 3.2rem;
        }
      }
    }
  }

  button {
    padding: 0%;
    transition: opacity 0.3s ease-in-out;
    border: 0;
    background: transparent;
    color: $text-dark;
    text-decoration: underline;
    cursor: pointer;

    @include typo-14-medium();

    &:hover {
      opacity: 0.6;
    }
  }

  &--success {
    .summary-box__stripe {
      background: $green;
    }

    .grade > p > span {
      color: $green;
    }
  }

  &--warning {
    .summary-box__stripe {
      background: $yellow;
    }

    .grade > p > span {
      color: $yellow;
    }
  }

  &--error {
    .summary-box__stripe {
      background: $red;
    }

    .grade > p > span {
      color: $red;
    }
  }
}
