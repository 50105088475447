$black: #000;
$white: #fff;
$white2: #fafafa;
$white3: #f0f0f0;

$grey: #e5e5e5;
$grey2: #7e7e7e;
$grey3: #eee7f4;
$grey3-light: lighten($grey3, 2);
$grey4: #efedf8;
$grey4-light: lighten($grey4, 2);

$light-purple: #f3e7fd;
$purple: #4f0a8a;
$light-purple: #c2bae4;
$dark-purple: #33075a;

$text-dark: #595959;
$text: #262626;

$red: #ff3133;
$light-red: #ffebeb;

$green: #35ca79;
$light-green: #ebfaf2;

$yellow: #ffa800;
$light-yellow: #fff7e6;

$main-shadow: 0 15px 30px 0 #0000001a;
$light-shadow: 0 15px 15px 0 rgba(194, 186, 228, 0.05);

$tablet: 768px;
$tabletD: 769px;
$mobile: 480px;
$mobileD: 481px;
